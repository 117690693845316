import React, {FC, memo} from 'react';
import LogoAmz123 from 'assets/friends/amz123.png';
import LogoDropshipping from 'assets/friends/dropshipping.svg';
import LogoEskimo from 'assets/friends/eskimo.png';
import LogoForbiz from 'assets/friends/forbiz.png';
import LogoKeyouyun from 'assets/friends/keyouyun.png';
import LogoMangoerp from 'assets/friends/mangoerp.jpeg';
import LogoMserp from 'assets/friends/miaoshouerp.png';
import LogoSsdz from 'assets/friends/shuaishoudianzhang.png';
import LogoWxw from 'assets/friends/wangxiaowang.png';
import LogoEasyBoss from 'assets/friends/easyboss.png';
import LogoShopify from 'assets/friends/shopify.png';
import LogoOzon from 'assets/friends/ozon.png';
import LogoCafe24 from 'assets/friends/cafe24.png';
import LogoLxhausys from 'assets/friends/lxhausys.png';
import LogoMetacommerce from 'assets/friends/metacommerce.png';
import LogoAlibaba from 'assets/friends/alibaba.png';
import LogoCouplus from 'assets/friends/couplus.png';
import LogoDianxiaomi from 'assets/friends/dianxiaomi.png';
import LogoDuoke from 'assets/friends/duoke.png';
import LogoBigSeller from 'assets/friends/bigseller.png';
import LogoDianxiaobao from 'assets/friends/dianxiaobao.png';
import LogoMabang from 'assets/friends/mabang.png';
import LogoMijingtong from 'assets/friends/mijingtong.png';
import LogoUnicomlife from 'assets/friends/unicomlife.png';
import LogoWuyouyishou from 'assets/friends/wuyouyishou.png';
import LogoHelplook from 'assets/friends/helplook.png';
import LogoUeeshop from 'assets/friends/ueeshop.png';
import * as S from './Styles';

interface ComponentProps {
  className?: string;
}

const list = [
  {link: 'https://erp.91miaoshou.com/', logo: LogoMserp},
  {link: 'https://www.keyouyun.com/', logo: LogoKeyouyun},
  {link: 'https://www.mangoerp.com/index', logo: LogoMangoerp},
  {height: 80, link: 'https://www.cjdropshipping.com/', logo: LogoDropshipping, width: 'auto'},
  {link: 'https://www.wxwerp.com/', logo: LogoWxw},
  // {link: 'https://www.forbiz.co.kr/', logo: LogoForbiz},
  // {link: 'http://www.eskimos.cn/', logo: LogoEskimo},
  {link: 'https://dz.shuaishou.com/home/index?link=xiangji', logo: LogoSsdz},
  {link: 'https://www.amz123.com/', logo: LogoAmz123},
  {link: 'https://share.helplook.net/jIBQJz', logo: LogoHelplook},
  {link: 'https://www.easyboss.com/', logo: LogoEasyBoss},
  {link: 'https://www.shopify.com/', logo: LogoShopify},
  {link: 'https://www.ozon.ru/', logo: LogoOzon},
  {link: 'https://www.cafe24.com/', logo: LogoCafe24},
  {link: 'https://www.lxhausys.com/', logo: LogoLxhausys},
  {link: 'https://www.meta-commerce.co.kr/', logo: LogoMetacommerce},
  {link: 'https://www.alibaba.com/', logo: LogoAlibaba},
  {height: 80, link: 'https://www.couplus.co.kr/', logo: LogoCouplus, width: 'auto'},
  {link: 'https://www.dianxiaomi.com/', logo: LogoDianxiaomi},
  {link: 'https://www.bigseller.com/zh_CN/index.htm?sourceChannelId=1307', logo: LogoBigSeller},
  {link: 'https://www.duoke.com/register.html?code=XJ', logo: LogoDuoke, width: '128'},
  {link: 'https://erp.dianxiaobao.net/', logo: LogoDianxiaobao},
  {link: 'https://www.mabangerp.com/index.htm', logo: LogoMabang},
  {link: 'https://www.miwaimao.com/', logo: LogoMijingtong},
  {link: 'https://erp.unicomlife.com/', logo: LogoUnicomlife},
  {link: 'https://www.51selling.com/', logo: LogoWuyouyishou},
  {link: 'https://www.ueeshop.com/', logo: LogoUeeshop, nofollow: true, title: 'ueeshop'},
];

const FriendshipLinks: FC<ComponentProps> = ({className}) => {
  return (
    <S.Container className={className}>
      {list.map((i) => {
        return (
          <S.LinkItemHref href={i.link} target="_blank" rel={i.nofollow ? 'nofollow' : undefined} title={i.title}>
            <S.LinkItem src={i.logo} alt={i.title} width={i.width || 188} height={i.height || 128} />
          </S.LinkItemHref>
        );
      })}
    </S.Container>
  );
};

export default memo(FriendshipLinks);
